// id = 0 es asistecia en linea
// id =  NaN para protocolos no grabados

import { blockPressotherapyBelt, blockPressotherapyBoots, blockPressotherapySleeve } from '../../appVersion';
import { blockCriotherapy, blockRotaheat, blockVenomBack, blockVenomKnee, blockVenomShoulder } from '../../appVersion';

// usarParametros de App version
// crear archivo de restricciones (ej: diabetes, embarazo, trombosis, cardiaco, etc)

const diabetes = [5, 9, 12, 18, 19, 20, 21, 22, 23, 34];

const pressotherapyBoots = [12, 19, 22, 23, 25];
const pressotherapySleeve = [5, 18, 20, 21, 34];
const pressotherapyBelt = [9];
const venomShoulder = [2, 26];
const venomKnee = [13, 40, 41, 42, 43, 44];
const venomBack = [10, 25, 27];
const criotherapy = [6, 7, 16, 20, 21, 22, 11, 17, 23];
const rotaheat = [28, 29, 30, 41];

const all = [
  ...pressotherapyBoots,
  ...pressotherapySleeve,
  ...pressotherapyBelt,
  ...venomShoulder,
  ...venomKnee,
  ...venomBack,
  ...criotherapy,
  ...rotaheat,
];

interface type_x extends NonNullable<unknown> {
  [index: string]: any;
}

interface rec {
  id: number;
}

export const lx: type_x = {
  l1: 'cuello',
  l2: 'hombros',
  l3: 'brazo / codo',
  l4: 'muñeca / mano',
  l5: 'espalda alta',
  l6: 'espalda baja / gluteo',
  l7: 'pierna',
  l8: 'rodilla',
  l9: 'tobillo / pie',
};

export const ax: type_x = {
  a1: '1 sem',
  a2: '1 sem - 1 mes',
  a3: '1 mes - 2 mes',
  a4: '+ 2 meses',
};

export const ix: type_x = {
  i1: 'malestar leve',
  i2: 'malestar moderado',
  i3: 'malestar intenso',
};

export const hx: type_x = {
  h0: 'sin hinchazón',
  h1: 'hinchazón',
};

export const tx: type_x = {
  t0: 'sin trauma',
  t1: 'trauma',
};

export const getRecomendation = (path: string, blockDiabetes: boolean | undefined, businessId: number) => {
  const pathArray = path.split('-');
  const userTree = generateTree(blockDiabetes, businessId);
  const [l, a, i, h, t] = pathArray;
  let recomendation = userTree.molestias[l][a][i];
  if (recomendation[h] !== undefined) {
    recomendation = userTree.molestias[l][a][i][h];
  }
  if (recomendation[t] !== undefined) {
    recomendation = userTree.molestias[l][a][i][h][t];
  }
  const elementosValidos = recomendation.filter((item: rec) => !isNaN(item.id) && item.id !== 0);
  return elementosValidos[0];
};

const recomendador: type_x = {
  molestias: {
    l1: {
      a1: {
        i1: [{ id: 1 }, { id: 28 }],
        i2: {
          h0: [{ id: 28 }, { id: 1 }],
          h1: [{ id: 0 }, { id: 28 }],
        },
        i3: {
          h0: [{ id: 28 }, { id: 1 }],
          h1: [{ id: 0 }],
        },
      },
      a2: {
        i1: [{ id: 1 }, { id: 28 }],
        i2: [{ id: 28 }, { id: 1 }],
        i3: [{ id: 28 }],
      },
      a3: {
        i1: [{ id: 1 }, { id: 28 }],
        i2: [{ id: 28 }, { id: 1 }],
        i3: [{ id: 0 }, { id: 28 }],
      },
      a4: {
        i1: [{ id: 1 }, { id: 28 }],
        i2: [{ id: 28 }, { id: 1 }],
        i3: [{ id: 0 }, { id: 28 }],
      },
    },
    l2: {
      a1: {
        i1: [{ id: 26 }, { id: 3 }, { id: 28 }, { id: 2 }, { id: 16 }, { id: 18 }],
        i2: {
          h0: [{ id: 3 }, { id: 26 }, { id: 2 }, { id: 28 }, { id: 16 }, { id: 18 }],
          h1: {
            t1: [{ id: 16 }, { id: 2 }],
            t0: [{ id: 2 }, { id: 16 }, { id: 18 }],
          },
        },
        i3: {
          h0: [{ id: 16 }, { id: 26 }, { id: 2 }, { id: 28 }, { id: 18 }],
          h1: {
            t1: [{ id: 0 }, { id: 16 }],
            t0: [{ id: 0 }, { id: 2 }, { id: 18 }, { id: 16 }],
          },
        },
      },
      a2: {
        i1: [{ id: 26 }, { id: 28 }, { id: 2 }, { id: 18 }, { id: 16 }],
        i2: {
          h0: [{ id: 3 }, { id: 28 }, { id: 2 }, { id: 16 }, { id: 18 }],
          h1: [{ id: 16 }, { id: 2 }, { id: 28 }, { id: 18 }],
        },
        i3: {
          h0: [{ id: 28 }, { id: 2 }, { id: 16 }, { id: 18 }],
          h1: [{ id: 0 }, { id: 16 }, { id: 2 }, { id: 18 }],
        },
      },
      a3: {
        i1: [{ id: 26 }, { id: 28 }, { id: 3 }, { id: 2 }],
        i2: {
          h0: [{ id: 26 }, { id: 28 }, { id: 2 }],
          h1: [{ id: 2 }, { id: 18 }, { id: 26 }, { id: 16 }, { id: 28 }],
        },
        i3: {
          h0: [{ id: 0 }, { id: 2 }, { id: 28 }, { id: 16 }],
          h1: [{ id: 0 }, { id: 18 }, { id: 2 }, { id: 16 }, { id: 28 }],
        },
      },
      a4: {
        i1: [{ id: 26 }, { id: 3 }, { id: 28 }, { id: 2 }],
        i2: {
          h0: [{ id: 26 }, { id: 28 }, { id: 2 }],
          h1: [{ id: 18 }, { id: 2 }, { id: 28 }, { id: 16 }],
        },
        i3: {
          h0: [{ id: 0 }, { id: 2 }, { id: 28 }],
          h1: [{ id: 0 }, { id: 18 }, { id: 28 }, { id: 16 }],
        },
      },
    },
    l3: {
      a1: {
        i1: [{ id: 4 }, { id: 5 }, { id: 6 }],
        i2: {
          h0: [{ id: 4 }, { id: 5 }, { id: 6 }],
          h1: {
            t1: [{ id: 6 }, { id: 20 }, { id: 34 }],
            t0: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 6 }],
          },
        },
        i3: {
          h0: [{ id: 6 }, { id: 20 }, { id: 5 }],
          h1: {
            t1: [{ id: 0 }, { id: 6 }, { id: 20 }, { id: 5 }, { id: 34 }],
            t0: [{ id: 0 }, { id: 34 }, { id: 6 }, { id: 5 }, { id: 20 }],
          },
        },
      },
      a2: {
        i1: [{ id: 4 }, { id: 20 }, { id: 6 }, { id: 5 }],
        i2: {
          h0: [{ id: 5 }, { id: 4 }, { id: 20 }, { id: 6 }],
          h1: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 4 }],
        },
        i3: {
          h0: [{ id: 6 }, { id: 5 }, { id: 20 }],
          h1: [{ id: 0 }, { id: 34 }, { id: 5 }, { id: 20 }],
        },
      },
      a3: {
        i1: [{ id: 4 }, { id: 5 }, { id: 20 }, { id: 6 }],
        i2: {
          h0: [{ id: 5 }, { id: 4 }, { id: 6 }, { id: 20 }],
          h1: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 4 }],
        },
        i3: {
          h0: [{ id: 5 }, { id: 4 }, { id: 6 }],
          h1: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 6 }],
        },
      },
      a4: {
        i1: [{ id: 4 }, { id: 5 }, { id: 20 }, { id: 6 }],
        i2: {
          h0: [{ id: 5 }, { id: 4 }, { id: 20 }, { id: 6 }],
          h1: [{ id: 5 }, { id: 34 }, { id: 20 }, { id: 4 }, { id: 6 }],
        },
        i3: {
          h0: [{ id: 0 }, { id: 6 }, { id: 5 }, { id: 4 }],
          h1: [{ id: 0 }, { id: 34 }, { id: 5 }, { id: 20 }],
        },
      },
    },
    l4: {
      a1: {
        i1: [{ id: 8 }, { id: 21 }],
        i2: {
          h0: [{ id: 21 }, { id: 8 }],
          h1: {
            t0: [{ id: 0 }, { id: 8 }, { id: 21 }],
            t1: [{ id: 21 }, { id: 7 }, { id: 8 }],
          },
        },
        i3: {
          h0: [{ id: 7 }, { id: 21 }, { id: 8 }],
          h1: {
            t0: [{ id: 0 }, { id: 21 }, { id: 8 }],
            t1: [{ id: 0 }, { id: 21 }, { id: 7 }],
          },
        },
      },
      a2: {
        i1: [{ id: 8 }, { id: 21 }, { id: 7 }],
        i2: {
          h0: [{ id: 8 }, { id: 21 }, { id: 7 }],
          h1: [{ id: 5 }, { id: 34 }, { id: 21 }],
        },
        i3: {
          h0: [{ id: 0 }, { id: 21 }],
          h1: [{ id: 0 }, { id: 34 }, { id: 21 }],
        },
      },
      a3: {
        i1: [{ id: 8 }, { id: 21 }, { id: 5 }],
        i2: {
          h0: [{ id: 8 }, { id: 5 }],
          h1: [{ id: 21 }, { id: 34 }, { id: 5 }, { id: 8 }],
        },
        i3: {
          h0: [{ id: 21 }, { id: 34 }, { id: 8 }],
          h1: [{ id: 0 }, { id: 34 }, { id: 21 }, { id: 5 }, { id: 8 }],
        },
      },
      a4: {
        i1: [{ id: 8 }, { id: 21 }, { id: 5 }],
        i2: {
          h0: [{ id: 8 }, { id: 5 }],
          h1: [{ id: 21 }, { id: 34 }, { id: 5 }, { id: 8 }],
        },
        i3: {
          h0: [{ id: 21 }, { id: 34 }, { id: 8 }],
          h1: [{ id: 0 }, { id: 34 }, { id: 21 }, { id: 5 }, { id: 8 }],
        },
      },
    },
    l5: {
      a1: {
        i1: [{ id: 28 }],
        i2: [{ id: 28 }],
        i3: {
          h0: [{ id: 28 }],
          h1: [{ id: 0 }],
        },
      },
      a2: {
        i1: [{ id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }],
        i3: [{ id: 0 }, { id: NaN }, { id: 28 }],
      },
      a3: {
        i1: [{ id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }],
        i3: [{ id: 0 }, { id: NaN }, { id: 28 }],
      },
      a4: {
        i1: [{ id: 28 }, { id: NaN }],
        i2: [{ id: 28 }, { id: NaN }],
        i3: [{ id: 0 }, { id: NaN }, { id: 28 }],
      },
    },
    l6: {
      a1: {
        i1: [{ id: 24 }, { id: 27 }, { id: 29 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: {
          h0: [{ id: 27 }, { id: 24 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
          h1: {
            t0: [{ id: 27 }, { id: 24 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
            t1: [{ id: 9 }, { id: 10 }],
          },
        },
        i3: {
          h0: [{ id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
          h1: {
            t0: [{ id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
            t1: [{ id: 0 }, { id: 9 }, { id: 10 }],
          },
        },
      },
      a2: {
        i1: [{ id: 24 }, { id: 27 }, { id: 29 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 10 }, { id: 29 }, { id: 24 }, { id: 27 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3: [{ id: 0 }, { id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
      },
      a3: {
        i1: [{ id: 24 }, { id: 29 }, { id: 27 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 27 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3: [{ id: 0 }, { id: 9 }, { id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
      },
      a4: {
        i1: [{ id: 24 }, { id: 29 }, { id: 27 }, { id: 10 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i2: [{ id: 27 }, { id: 29 }, { id: 10 }, { id: 9 }, { id: 31 }, { id: 32 }, { id: 33 }],
        i3: [{ id: 0 }, { id: 9 }, { id: 10 }, { id: 29 }, { id: 31 }, { id: 32 }, { id: 33 }],
      },
    },
    l7: {
      a1: {
        i1: [{ id: 14 }, { id: 19 }, { id: 30 }, { id: 32 }],
        i2: {
          h0: [{ id: 19 }, { id: 14 }, { id: 30 }, { id: 32 }],
          h1: {
            t0: [{ id: 0 }, { id: 12 }, { id: 14 }, { id: 30 }],
            t1: [{ id: 12 }, { id: 30 }],
          },
        },
        i3: {
          h0: [{ id: 30 }, { id: 19 }, { id: 32 }],
          h1: {
            t0: [{ id: 0 }, { id: 12 }],
            t1: [{ id: 0 }, { id: 12 }],
          },
        },
      },
      a2: {
        i1: [{ id: 14 }, { id: 30 }, { id: 19 }, { id: 32 }],
        i2: {
          h0: [{ id: 30 }, { id: 19 }, { id: 14 }, { id: 32 }],
          h1: [{ id: 12 }, { id: 32 }],
        },
        i3: {
          h0: [{ id: 19 }, { id: 30 }, { id: 32 }],
          h1: [{ id: 0 }, { id: 12 }],
        },
      },
      a3: {
        i1: [{ id: 14 }, { id: 30 }, { id: 19 }, { id: 32 }],
        i2: {
          h0: [{ id: 30 }, { id: 14 }, { id: 19 }, { id: 32 }],
          h1: [{ id: 0 }, { id: 12 }],
        },
        i3: {
          h0: [{ id: 19 }, { id: 30 }, { id: 14 }, { id: 32 }],
          h1: [{ id: 0 }, { id: 12 }],
        },
      },
      a4: {
        i1: [{ id: 14 }, { id: 30 }, { id: 19 }, { id: 32 }],
        i2: {
          h0: [{ id: 30 }, { id: 14 }, { id: 19 }, { id: 32 }],
          h1: [{ id: 0 }, { id: 12 }],
        },
        i3: {
          h0: [{ id: 19 }, { id: 30 }, { id: 14 }, { id: 32 }],
          h1: [{ id: 0 }, { id: 12 }],
        },
      },
    },
    l8: {
      a1: {
        i1: [{ id: 22 }, { id: 11 }, { id: 13 }],
        i2: {
          h0: [{ id: 22 }, { id: 11 }, { id: 13 }],
          h1: {
            t0: [{ id: 12 }, { id: 13 }],
            t1: [{ id: 22 }, { id: 11 }],
          },
        },
        i3: {
          h0: [{ id: 0 }, { id: 11 }, { id: 13 }, { id: 22 }],
          h1: {
            t0: [{ id: 0 }, { id: 12 }, { id: 13 }],
            t1: [{ id: 0 }, { id: 11 }, { id: 22 }],
          },
        },
      },
      a2: {
        i1: [{ id: 13 }, { id: 22 }, { id: 11 }],
        i2: {
          h0: [{ id: 22 }, { id: 13 }, { id: 11 }],
          h1: [{ id: 12 }, { id: 22 }, { id: 13 }],
        },
        i3: {
          h0: [{ id: 11 }, { id: 22 }, { id: 13 }],
          h1: [{ id: 0 }, { id: 12 }, { id: 22 }, { id: 13 }],
        },
      },
      a3: {
        i1: [{ id: 13 }, { id: 22 }, { id: 11 }],
        i2: {
          h0: [{ id: 22 }, { id: 13 }, { id: 11 }],
          h1: [{ id: 12 }, { id: 22 }, { id: 13 }],
        },
        i3: {
          h0: [{ id: 11 }, { id: 22 }, { id: 13 }],
          h1: [{ id: 0 }, { id: 12 }, { id: 22 }, { id: 13 }],
        },
      },
      a4: {
        i1: [{ id: 13 }, { id: 22 }, { id: 11 }],
        i2: {
          h0: [{ id: 22 }, { id: 13 }, { id: 11 }],
          h1: [{ id: 12 }, { id: 22 }, { id: 13 }],
        },
        i3: {
          h0: [{ id: 0 }, { id: 11 }, { id: 22 }, { id: 13 }],
          h1: [{ id: 0 }, { id: 12 }, { id: 22 }],
        },
      },
    },
    l9: {
      a1: {
        i1: [{ id: 17 }, { id: 15 }],
        i2: {
          h0: [{ id: 15 }, { id: 17 }],
          h1: {
            t0: [{ id: 12 }, { id: 23 }],
            t1: [{ id: 17 }, { id: 23 }, { id: 12 }],
          },
        },
        i3: {
          h0: [{ id: 0 }, { id: 17 }, { id: 23 }],
          h1: {
            t0: [{ id: 0 }, { id: 17 }, { id: 23 }, { id: 12 }],
            t1: [{ id: 0 }, { id: 17 }, { id: 23 }],
          },
        },
      },
      a2: {
        i1: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2: {
          h0: [{ id: 23 }, { id: 17 }, { id: 15 }],
          h1: [{ id: 12 }, { id: 23 }],
        },
        i3: {
          h0: [{ id: 17 }, { id: 23 }, { id: 15 }],
          h1: [{ id: 0 }, { id: 23 }, { id: 12 }],
        },
      },
      a3: {
        i1: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2: {
          h0: [{ id: 23 }, { id: 15 }, { id: 17 }],
          h1: [{ id: 12 }, { id: 23 }],
        },
        i3: {
          h0: [{ id: 17 }, { id: 23 }],
          h1: [{ id: 0 }, { id: 23 }, { id: 12 }],
        },
      },
      a4: {
        i1: [{ id: 15 }, { id: 23 }, { id: 17 }],
        i2: {
          h0: [{ id: 15 }, { id: 23 }, { id: 17 }],
          h1: [{ id: 12 }, { id: 23 }],
        },
        i3: {
          h0: [{ id: 17 }, { id: 23 }],
          h1: [{ id: 0 }, { id: 23 }, { id: 17 }, { id: 12 }],
        },
      },
    },
  },
  hinchazon: {
    l2: [{ id: 16 }],
    l3: [{ id: 6 }, { id: 20 }, { id: 5 }],
    l4: [{ id: 7 }, { id: 21 }],
    l7: [{ id: 12 }],
    l8: [{ id: 11 }, { id: 22 }],
    l9: [{ id: 12 }, { id: 17 }, { id: 23 }],
  },
  cansancio: {
    s0: {
      l2: [{ id: 2 }],
      l3: [{ id: 5 }],
      l6: [{ id: 10 }, { id: 9 }],
      l7: [{ id: 19 }],
    },
    s1: [{ id: 39 }],
  },
  tension: {
    l1: [{ id: 1 }],
    l2: [{ id: 2 }, { id: 3 }],
    l3: [{ id: 4 }],
    l4: [{ id: 8 }],
    l6: [{ id: 10 }],
    l7: [{ id: 14 }, { id: 13 }],
    l9: [{ id: 15 }],
  },
  relajacion: [{ id: 25 }],
};

export default recomendador;

export const generateTree = (blockDiabetes: boolean | undefined, businessId: number) => {
  const newRecomendador = JSON.parse(JSON.stringify(recomendador));

  const cleanArray = (arr: rec[]) => {
    return arr.filter((item) => {
      if (isNaN(item.id) || item.id === 0) {
        return true;
      }

      const allowPressotherapyBoots =
        pressotherapyBoots.includes(item.id) && !blockPressotherapyBoots.includes(businessId);
      const allowPressotherapySleeve =
        pressotherapySleeve.includes(item.id) && !blockPressotherapySleeve.includes(businessId);
      const allowPressotherapyBelt =
        pressotherapyBelt.includes(item.id) && !blockPressotherapyBelt.includes(businessId);
      const allowVenomKnee = venomKnee.includes(item.id) && !blockVenomKnee.includes(businessId);
      const allowVenomBack = venomBack.includes(item.id) && !blockVenomBack.includes(businessId);
      const allowVenomShoulder = venomShoulder.includes(item.id) && !blockVenomShoulder.includes(businessId);
      const allowCriotherapy = criotherapy.includes(item.id) && !blockCriotherapy.includes(businessId);
      const allowRotaheat = rotaheat.includes(item.id) && !blockRotaheat.includes(businessId);
      const isInDiabetes = diabetes.includes(item.id);
      return (
        (!all.includes(item.id) && !isInDiabetes) ||
        ((allowPressotherapyBoots ||
          allowPressotherapySleeve ||
          allowPressotherapyBelt ||
          allowVenomKnee ||
          allowVenomBack ||
          allowVenomShoulder ||
          allowCriotherapy ||
          allowRotaheat) &&
          !isInDiabetes &&
          blockDiabetes) ||
        ((allowPressotherapyBoots ||
          allowPressotherapySleeve ||
          allowPressotherapyBelt ||
          allowVenomKnee ||
          allowVenomBack ||
          allowVenomShoulder ||
          allowCriotherapy ||
          allowRotaheat) &&
          !blockDiabetes)
      );
    });
  };

  const cleanObject = (obj: any): any => {
    if (Array.isArray(obj)) {
      const cleaned = cleanArray(obj);
      if (cleaned.length === 0 || (cleaned.length === 1 && cleaned[0]?.id === 0)) {
        return undefined;
      }
      return cleaned;
    }

    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    const result: any = {};
    let blockValidChildren = false;

    for (const key in obj) {
      const cleaned = cleanObject(obj[key]);
      if (cleaned !== undefined) {
        result[key] = cleaned;
        blockValidChildren = true;
      }
    }

    if (!blockValidChildren) {
      return undefined;
    }

    const keys = Object.keys(result);
    if (keys.length === 1) {
      return result[keys[0]];
    }

    return result;
  };

  const cleanedRecomendador = cleanObject(newRecomendador);
  return cleanedRecomendador;
};

import React, { FC, useState, useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { generateTree } from './tree';
import { Box, Button, Stack, Chip, Typography } from '@mui/material';
import MenuCategory from '../HomeScreenComponents/Category';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import languages from '../../language/languages';

const Recommender: FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const { selected } = useSelector((state: RootState) => state.language);
  const [query, setQuery] = useState<any>();
  const [currentNode, setCurrentNode] = useState<any>(null);
  const [path, setPath] = useState<string[]>([]);
  const [result, setResult] = useState<any>(null);

  useEffect(() => {
    const userTree = generateTree(user.thrombosis, user?.business_id || 1);
    console.log(userTree);
    setQuery(userTree);
    setCurrentNode(userTree);
  }, [user]);

  const handleOptionClick = (option: string) => {
    const nextNode = currentNode[option];

    setPath([...path, option]);

    if (Array.isArray(nextNode)) {
      setResult(nextNode);
      setCurrentNode(null);
    } else if (typeof nextNode !== 'object' || nextNode === null) {
      setResult(nextNode);
      setCurrentNode(null);
    } else {
      setCurrentNode(nextNode);
    }
  };

  const resetForm = () => {
    setCurrentNode(query);
    setPath([]);
    setResult(null);
  };

  const renderOptions = () => {
    if (!currentNode) return null;

    return Object.keys(currentNode).map((option) => (
      <Button
        key={option}
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
        variant="contained"
        onClick={() => handleOptionClick(option)}>
        {languages[selected].charlie[option]}
      </Button>
    ));
  };

  // Show loading state while tree is being generated
  if (!query) {
    return <Typography>Cargando recomendaciones...</Typography>;
  }

  return (
    <Box sx={{ width: '100vw' }}>
      <Box sx={{ paddingX: '24px' }}>
        <Stack justifyContent="flex-start" alignItems="flex-start" direction="row" useFlexGap display="inline-block">
          <Chip
            sx={{ margin: '5px', fontWeight: 'bold', color: '#1b4ed8' }}
            key={`chip-reboot`}
            label="REINICIAR"
            onClick={resetForm}
            icon={<RestartAltIcon color="primary" />}
          />
          {path.map((step, index) => (
            <Chip
              sx={{ margin: '5px', fontWeight: 'bold', color: '#1b4ed8' }}
              key={index}
              label={languages[selected].charlie[step]}
            />
          ))}
        </Stack>
      </Box>
      <Box sx={{ width: '100vw' }}>
        {result ? (
          <Box>
            <MenuCategory specs={{ name: 'Recomendado', protocolIds: result.map((p: any) => p.id) }} />
          </Box>
        ) : (
          <Box sx={{ p: '24px', width: '100vw' }}>
            <Typography variant="subtitle1" gutterBottom>
              {currentNode === query
                ? languages[selected].charlie.title
                : languages[selected].charlie[Object.keys(currentNode)[0].substring(0, 1)]}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{renderOptions()}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Recommender;

import React, { FC, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

const LockTest: FC = () => {
  const { business_id } = useSelector((state: RootState) => state.user);
  const [accessCode, setAccessCode] = useState(0);

  const generateAccessCode = async (secretKey: number) => {
    const utcTime = Date.now();
    const intervalStart = utcTime - (utcTime % 300000);
    const data = `${intervalStart}${secretKey}`;

    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);

    const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    const code = parseInt(hashHex.slice(-2), 16) % 100;
    setAccessCode(code);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (business_id == 1) {
      intervalId = setInterval(() => {
        generateAccessCode(1);
      }, 1000);
    } else {
      intervalId = setInterval(() => {
        generateAccessCode(2);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ padding: '2vw', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
        <LockIcon />
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          El codigo es {accessCode.toString().padStart(2, '0')}#
        </Typography>
      </Box>
    </Box>
  );
};

export default LockTest;

import React, { FC, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Box, FormControl, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/slices/userSlice';
import { getRecomendation } from '../Recommender/tree';
import * as protocolInteractors from '../../redux/interactors/ProtocolInteractors';
import * as registerInteractors from '../../redux/interactors/registerInteractors';
import ReactMarkdown from 'react-markdown';
import languages from '../../language/languages';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <h1 className="npnm">😩</h1>,
    label: '5',
  },
  2: {
    icon: <h1 className="npnm">😞</h1>,
    label: '4',
  },
  3: {
    icon: <h1 className="npnm">😐</h1>,
    label: '3',
  },
  4: {
    icon: <h1 className="npnm">🙂</h1>,
    label: '2',
  },
  5: {
    icon: <h1 className="npnm">😄</h1>,
    label: '1',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

interface Props {
  selectProtocolInteractor: typeof protocolInteractors.selectProtocolInteractor;
  updateRegisterInInteractor: typeof registerInteractors.updateRegisterInInteractor;
  postRegisterInInteractor: typeof registerInteractors.postRegisterInInteractor;
  resetRegisterInInteractor: typeof registerInteractors.resetRegisterInInteractor;
}

const RegisterInForm: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plan } = useParams();
  const { id, thrombosis, business_id } = useSelector((state: RootState) => state.user);
  const { selected } = useSelector((state: RootState) => state.language);
  const [fatigue, setFatigue] = useState(NaN);
  const [musclePain, setMusclePain] = useState(NaN);
  const [feeling, setFeeling] = useState(NaN);
  const [protocolId, setProtocolId] = useState(NaN);
  const bodyPart = languages[selected].register.bodyPart(plan ? plan : '');

  const register = async () => {
    props.postRegisterInInteractor({
      fatigue: 6 - fatigue,
      pain: 6 - musclePain,
      general: 6 - feeling,
      user_id: id,
    });
    dispatch(userActions.setMode({ mode: 'active-out' }));
    if (plan !== 'no') {
      props.selectProtocolInteractor(protocolId);
    }
    navigate(`/routine/${plan?.substring(0, 6)}i${musclePain}${plan?.substring(8)}`);
  };

  const fatigueHandler = (event: any) => {
    setFatigue(+event.target.value);
    props.updateRegisterInInteractor({ name: 'fatigue', value: +event.target.value });
  };

  const feelingHandler = (event: any) => {
    setFeeling(+event.target.value);
    props.updateRegisterInInteractor({ name: 'general', value: +event.target.value });
  };

  const handlePain = (event: any, value: any) => {
    setMusclePain(+value);
    props.updateRegisterInInteractor({ name: 'pain', value: +event.target.value });
    let newI = 6 - value;
    if (newI <= 2) {
      newI = 1;
    } else if (newI <= 4) {
      newI = 2;
    } else {
      newI = 3;
    }
    if (plan !== 'no') {
      const recomendation = getRecomendation(
        `${plan?.substring(0, 6)}i${newI}${plan?.substring(8)}`,
        thrombosis,
        business_id || 1,
      );
      console.log(recomendation);
      setProtocolId(recomendation.id);
    }
  };

  return (
    <FormControl>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q1}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={fatigue}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={fatigueHandler}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q1ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q1ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q2(bodyPart)}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          value={musclePain}
          onChange={handlePain}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q2ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q2ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ margin: '20px' }}>
        <Typography component="legend">
          <ReactMarkdown className="mdp">{languages[selected].register.q3In}</ReactMarkdown>
        </Typography>
        <StyledRating
          size="large"
          name="highlight-selected-only"
          value={feeling}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={feelingHandler}
        />
        <Stack direction={'row'} spacing={'50%'}>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q3ScaleLow}
          </Typography>
          <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>
            {languages[selected].register.q3ScaleHigh}
          </Typography>
        </Stack>
      </Box>
      {fatigue !== null &&
        musclePain !== null &&
        feeling !== null &&
        isNaN(fatigue) === false &&
        isNaN(musclePain) === false &&
        isNaN(feeling) === false && (
          <Box sx={{ margin: '20px' }}>
            <Button
              onClick={register}
              variant="contained"
              size="large"
              sx={{ borderRadius: '50px', fontWeight: 'bold', width: '100%' }}>
              {languages[selected].register.ctaIn}
            </Button>
          </Box>
        )}
    </FormControl>
  );
};

const mapDispatchToProps = (dispatch: any): Props => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
      ...registerInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(RegisterInForm);

import React, { FC, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Box, Link } from '@mui/material';
import PlanCarousel from '../components/WorkoutPlan/PlanCarousel';
import PointsCard from '../components/HomeScreenComponents/PointsCard';
import languages from '../language/languages';
import db, { preloadImages, Database } from '../database/db';
import ReservationsCarousel from '../components/HomeScreenComponents/ReservationsCarousel';
import userService from '../services/UserServices';
import { userActions } from '../redux/slices/userSlice';
import { bodyActions } from '../redux/slices/bodySlice';
import { useNavigate } from 'react-router-dom';
import * as protocolInteractors from '../redux/interactors/ProtocolInteractors';
import { bindActionCreators } from 'redux';
import { reservationsModule } from '../appVersion';
import { generateTree } from '../components/Recommender/tree';

declare const progressier: any;

interface DispatchProps {
  selectProtocolInteractor: typeof protocolInteractors.selectProtocolInteractor;
}

interface Props extends DispatchProps {
  // extra props you want to add
}

const Home: FC<Props> = (props: Props) => {
  const { email, phone, sex, business_id, last_name, first_name, offline } = useSelector(
    (state: RootState) => state.user,
  );
  const { selected } = useSelector((state: RootState) => state.language);
  const { token, diabetes } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const imagesPreloaded = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoad = () => {
      progressier.add({
        email: email,
        phone: phone,
        name: `${first_name} ${last_name}`,
        tags: `${sex}, ${business_id}`,
      });
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (offline && !imagesPreloaded.current) {
      preloadImages();
      imagesPreloaded.current = true;
    } else if (!offline) {
      (db as Database).imagenes.clear();
    }
  }, [offline]);

  const redirection = async (token: string) => {
    const response = await userService.getRedirect(token);
    if (response) {
      const { redirect } = response;
      dispatch(userActions.redirect({ redirect }));
    } else {
      dispatch(userActions.redirect({ redirect: false }));
    }
  };

  useEffect(() => {
    // Run! Like go get some data from an API.
    generateTree(diabetes || true, business_id || 0);
    if (token) {
      redirection(token);
    }
  }, []);

  const onClick = () => {
    dispatch(bodyActions.unselect());
    props.selectProtocolInteractor(39);
    if (window.location.pathname.includes('/routine') === false) {
      navigate('/in/no');
      const now = new Date();
      dispatch(userActions.setMode({ mode: 'active-in' }));
      dispatch(userActions.setStarted({ started: now.toISOString() }));
    }
  };

  return (
    <Box sx={{ paddingTop: '10vh' }}>
      {reservationsModule.includes(business_id || 0) ? (
        <ReservationsCarousel />
      ) : (
        <>
          <Box sx={{ textAlign: 'left', marginX: '5vw', marginY: '0', paddingY: '0' }}>
            {first_name && (
              <h2 className="App-logo-text">
                {languages[selected].home.welcome(first_name)} {sex === 'F' ? `👩‍🚀` : `👨‍🚀`}
              </h2>
            )}
          </Box>
          <PointsCard />
        </>
      )}
      <PlanCarousel />
      <Box
        sx={{
          borderRadius: '20px',
          backgroundImage: 'url(https://cdn.makana.cl/mkn-591.jpg)',
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100% auto`,
          backgroundPosition: 'center',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          minWidth: {
            xs: '90vw', // Dispositivos móviles
            sm: '400px', // Otros tamaños de pantalla
          },
          minHeight: '20vh',
          marginX: '5vw',
          marginTop: 3,
          marginBottom: 3,
          paddingBottom: '0vh',
        }}>
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: 'linear-gradient(to bottom right,#432680a0, #0068ffc0)',
            paddingBottom: '4vh',
            paddingTop: '0.5vh',
            width: '100%',
            height: '100%',
            minWidth: {
              xs: '90vw', // Dispositivos móviles
              sm: '400px', // Otros tamaños de pantalla
            },
            minHeight: '20vh',
            margin: '0vh',
          }}>
          <Box sx={{ marginX: '2vw', marginY: '5vw', paddingY: '0', fontSize: { sm: 24 }, lineHeight: { sm: 2.3 } }}>
            <h1 className="App-profile-text">{languages[selected].home.recommender.title}</h1>
          </Box>
          <Link
            href="/charlie"
            sx={{
              color: 'primary.main',
              backgroundColor: '#FFFFFFc0',
              width: '100%',
              borderRadius: '50px',
              fontWeight: 'bold',
              boxShadow: 'none',
              paddingY: '2vh',
              paddingX: '5vw',
              textDecoration: 'none',
            }}>
            {languages[selected].home.recommender.button}
          </Link>
        </Box>
      </Box>
      {business_id === 29 && (
        <Box
          sx={{
            borderRadius: '20px',
            backgroundImage: 'url(https://cdn.makana.cl/mkn-153.jpg)',
            backgroundRepeat: `no-repeat`,
            backgroundSize: `100% auto`,
            backgroundPosition: 'center',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            minWidth: {
              xs: '90vw', // Dispositivos móviles
              sm: '400px', // Otros tamaños de pantalla
            },
            minHeight: '20vh',
            marginX: '5vw',
            marginBottom: 15,
            paddingBottom: '0vh',
          }}>
          <Box
            sx={{
              borderRadius: '20px',
              backgroundImage: 'linear-gradient(to bottom right,#432680a0, #0068ffc0)',
              paddingBottom: '4vh',
              paddingTop: '0.5vh',
              width: '100%',
              height: '100%',
              minWidth: {
                xs: '90vw', // Dispositivos móviles
                sm: '400px', // Otros tamaños de pantalla
              },
              minHeight: '20vh',
              margin: '0vh',
            }}>
            <Box sx={{ marginX: '2vw', marginY: '5vw', paddingY: '0', fontSize: { sm: 24 }, lineHeight: { sm: 2.3 } }}>
              <h1 className="App-profile-text">
                <Box sx={{ margin: 0, padding: 0, fontWeight: 'regular', fontSize: { sm: 50 } }}>
                  RUTINA DE
                  <Box
                    sx={{
                      fontSize: { xs: 35, sm: 40 },
                      margin: 0,
                      padding: 0,
                      width: '100%',
                      textAlign: 'right',
                      lineHeight: 0.8,
                      fontWeight: 'bold',
                    }}>
                    FATIGA Y SOMNOLENCIA
                  </Box>
                </Box>
              </h1>
            </Box>
            <Link
              onClick={onClick}
              sx={{
                color: 'primary.main',
                backgroundColor: '#FFFFFFc0',
                width: '100%',
                borderRadius: '50px',
                fontWeight: 'bold',
                boxShadow: 'none',
                paddingY: '2vh',
                paddingX: '5vw',
                textDecoration: 'none',
              }}>
              Hagámoslo!
            </Link>
          </Box>
        </Box>
      )}
      <Box sx={{ marginY: '15vh' }}></Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  ...bindActionCreators(
    {
      ...protocolInteractors,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(Home);

import { Language } from '../redux/types/languageTypes';

export const en: Language = {
  home: {
    welcome: (name: string) => `Hello, ${name}!`,
    points: (points: number, position: number) =>
      `🏆 You have ${points} points in the Makana challenge and you are in position #${position} in the ranking!`,
    planCarousel: {
      title: 'We recommend... 🔥',
      cta0: "Let's do it!",
      cta1: "Let's do it!",
      cta2: "Let's do it!",
      cta3: "Let's do it!",
      cardPhrase: {
        singular: 'RELIEVE YOUR',
        plural: 'RELIEVE YOUR',
        default: 'Continue with YOUR plan',
        countPhrase: (count: number, sessions: number) => `You have ${count} / ${sessions} sessions this week!`,
      },
      bodyParts: {
        neck: 'NECK',
        shoulders: 'SHOULDERS',
        arms: 'ARM / ELBOW',
        hands: 'WRIST / HAND',
        upperBack: 'UPPER BACK',
        lowerBack: 'LOWER BACK',
        legs: 'HIPS / LEGS',
        knees: 'KNEES',
        feet: 'ANKLES / FEET',
      },
    },
    recommender: {
      title: 'Want to try something DIFFERENT?',
      button: 'FIND IT HERE',
    },
  },
  body: {
    menuTitle: 'Routines',
    bodyParts: {
      head: 'Neck',
      trapezius: 'Neck',
      chest: '',
      stomach: '',
      leftShoulder: 'Shoulder',
      leftArm: 'Arm / Elbow',
      leftHand: 'Wrist / Hand',
      leftLeg: 'Leg / Knee',
      leftFoot: 'Foot / Sole of the Foot',
      rightShoulder: 'Shoulder',
      rightArm: 'Arm / Elbow',
      rightHand: 'Wrist / Hand',
      rightLeg: 'Leg / Knee',
      rightFoot: 'Foot / Sole of the Foot',
      lowerBack: 'Lower Back / Buttock',
      upperBack: 'Upper Back',
    },
  },
  others: {
    cards: {
      cta: 'See more',
    },
    exit: 'Exit',
  },
  profile: {
    title: 'Hello',
    sex: (sex: string) => (sex === 'M' ? 'Male' : sex === 'F' ? 'Female' : '-'),
    diabetes: 'Diabetes',
    thrombosis: 'Thrombosis',
    heartDiseases: 'Heart Diseases',
    editCta: 'Edit Profile',
    plan: {
      title: '**YOUR** Plan: ',
      badge: (plan: string) => {
        let badge = 'PREVENTIVE PLAN';
        if (plan === 'c3') {
          badge = 'HIGH CORRECTIVE PLAN';
        } else if (plan === 'c2') {
          badge = 'MEDIUM CORRECTIVE PLAN';
        } else if (plan === 'c1') {
          badge = 'LOW CORRECTIVE PLAN';
        }
        return badge;
      },
      description: (plan: string) => {
        let explanation = `You have not reported any significant discomfort, so we recommend that you visit the service twice a week
          to **PREVENT** discomfort in the future. **REMEMBER** that your body is your main work tool!`;
        if (plan === 'c3') {
          // Riesgo alto
          explanation = `You have reported **HIGH INTENSITY** discomfort, so as the Makana team, we recommend that you visit the service 
            at least **5 TIMES** a week, and continue with your plan in each session until you **ELIMINATE** these discomforts.`;
        } else if (plan === 'c2') {
          // Riesgo medio
          explanation = `When reporting **MEDIUM INTENSITY** discomfort, as the Makana team, we recommend you visit our service at least
            **5 TIMES** a week. Continue with your plan in each session until you **ELIMINATE** these discomforts. We are here to help you!`;
        } else if (plan === 'c1') {
          // Riesgo bajo
          explanation = `You have mentioned having **MILD** discomfort, so as the Makana team, we recommend using the service at least **3 TIMES**
            a week, and continue with your plan in each session until you ELIMINATE these discomforts. Remember that your well-being is essential
            for your performance.`;
        }
        return explanation;
      },
      priorities: '**YOUR** Priorities',
      priorityBadge: (priority: string) => {
        switch (priority[1]) {
          case '1':
            return 'Neck';
          case '2':
            return 'Shoulders';
          case '3':
            return 'Arm / Elbow';
          case '4':
            return 'Wrist / Hand';
          case '5':
            return 'High Back';
          case '6':
            return 'Lower Back';
          case '7':
            return 'Hips / Legs';
          case '8':
            return 'Knees';
          case '9':
            return 'Ankles / Feet';
          default:
            return 'No preferences';
        }
      },
      editCta: 'Modify Plan',
    },
    saved: 'My Routines',
    buttons: {
      notifications: 'Allow Notifications',
      instagram: 'Makana Community',
      facebook: 'Makana Community',
    },
    sofi: 'Fatigue Level Questionnaire',
    sessionsCount: (count: number) => `${count} sessions completed`,
    nordic: 'Musculoskeletal Pain Questionnaire',
    logout: 'Log Out',
  },
  ranking: {
    points: (points: number, position: number) =>
      `You have ${points} points in the Makana challenge and you are currently #${position} in the ranking!`,
    subtitle: 'Keep earning points to win the challenge and win incredible prizes!',
    badges: {
      title: 'Badges',
      subtitle:
        'Become a master of achievements! Earn exclusive badges by conquering challenges and reach a new level of WELL-BEING. Are you ready to shine?',
    },
    terms: {
      cta: 'View the Challenge Terms & Conditions',
    },
  },
  register: {
    q1: 'How **TIRED** do you feel?',
    q1ScaleLow: 'Tired',
    q1ScaleHigh: 'Rested',
    q2: (bodyPart: string) =>
      `How much **PHYSICAL DISCOMFORT** do you have${bodyPart !== '' ? ` in ${bodyPart}` : ''}?`,
    q2ScaleLow: 'Severe Discomfort',
    q2ScaleHigh: 'No Discomfort',
    q3In: 'How do you feel **NOW** (before the session)?',
    q3Out: 'How do you feel NOW (after the session)?',
    q3ScaleLow: 'Bad',
    q3ScaleHigh: 'Good',
    ctaIn: "LET'S GO!",
    ctaOut: 'END SESSION',
    bodyPart: (url: string) => {
      switch (url[1]) {
        case '1':
          return 'your neck';
        case '2':
          return 'your shoulders';
        case '3':
          return 'your arm/elbow';
        case '4':
          return 'your wrist/hand';
        case '5':
          return 'your upper back';
        case '6':
          return 'your lower back';
        case '7':
          return 'your hips/legs';
        case '8':
          return 'your knees';
        case '9':
          return 'your ankles/feet';
        default:
          return '';
      }
    },
  },
  nordic: {
    back: 'Back',
    next: 'Next',
    intro: {
      title: 'Hi there,',
      body: 'Please answer the following questionnaire carefully so we can recommend routines tailored to YOU and ensure a **better experience** at the **Makana** stations.',
    },
    body: {
      firstCheck: '12 months',
      weekCheck: '7 days',
      question: 'Check the areas where you have experienced discomfort or pain in the last ',
      cuello: 'Neck',
      hombro: 'Shoulders',
      brazoCodo: 'Arm/Elbow',
      manoMuñeca: 'Hand/Wrist',
      espaldaAlta: 'Upper Back',
      espaldaBaja: 'Lower Back',
      caderaPierna: 'Hips/Legs',
      rodilla: 'Knees',
      tobilloPie: 'Ankles/Feet',
      scaleQuestion: 'How severe was the pain over the last 7 days?',
      scaleLow: '< LOW pain',
      scaleHigh: 'HIGH pain >',
      intensity: {
        low: 'LOW intensity',
        mid: 'MEDIUM intensity',
        high: 'HIGH intensity',
        veryHigh: 'VERY HIGH intensity',
      },
    },
    partDetail: {
      zero: '0 days',
      week: '1 to 7 days',
      month: '8 to 30 days',
      more: 'More than 30 days',
      all: 'Every day',
      yes: 'Yes',
      no: 'No',
      q1: (tag: string) => `What is the total time you have had issues in **${tag}** over the **last 12 months**?`,
      q2: 'Is your discomfort the result of a **recent bump, fall, or twist**?',
      q3: 'Do you have **swelling or inflammation** in the area?',
      lowerBackDetails: 'the lower back',
      neckDetails: 'the neck',
      shouldersDetails: 'the shoulder',
      elbowsDetails: 'the elbow',
      handsDetails: 'the hand/wrist',
      upperBackDetails: 'the upper back',
      legsDetails: 'the hip/leg',
      kneesDetails: 'the knee',
      anklesDetails: 'the ankle/foot',
    },
    submit: 'SEND',
    waiting: 'We are building YOUR plan...',
    plan: {
      description: (plan: string, priority1: string, priority2: string, x: number, y: number, z: number) => {
        return `${en.nordic.plan.setPriorities(
          en.nordic.plan.priorityBadge(priority1),
          en.nordic.plan.priorityBadge(priority2),
          z,
        )} ${en.nordic.plan.setPain(x, y, z)}${en.nordic.plan.setPlan(plan)}${en.nordic.plan.setRandomEnd()}`;
      },
      priorityBadge: (priority: string) => {
        switch (priority[1]) {
          case '1':
            return 'NECK';
          case '2':
            return 'SHOULDERS';
          case '3':
            return 'ARM / ELBOW';
          case '4':
            return 'WRIST / HAND';
          case '5':
            return 'UPPER BACK';
          case '6':
            return 'LOWER BACK';
          case '7':
            return 'HIPS / LEGS';
          case '8':
            return 'KNEES';
          case '9':
            return 'ANKLES / FEET';
          default:
            return 'There is no preference';
        }
      },
      setPain: (x: number, y: number, z: number) => {
        let pain = 'You have ';
        if (y > 0) {
          if (y <= 2) {
            pain += 'acute discomfort';
          } else {
            pain += 'prolonged discomfort';
          }
          if (x >= 9) {
            pain += ' of **HIGH INTENSITY**';
          } else if (x >= 7) {
            pain += ' of **MEDIUM INTENSITY**';
          } else if (x >= 4) {
            pain += ' of **LOW INTENSITY**';
          } else {
            pain += ' that is not significant';
          }
          if (z > 4) {
            pain += ', and you are also experiencing generalized discomfort';
          }
        } else {
          pain += 'no significant discomfort';
          if (z > 4) {
            pain += ', but you are experiencing generalized discomfort';
          }
        }
        return pain;
      },
      setPlan: (plan: string) => {
        let badge =
          ', so we have placed you on the **PREVENTIVE** plan, recommending using Makana twice a week to **PREVENT** future discomfort.';
        if (plan === 'c3') {
          badge =
            ', so we have placed you on the **HIGH CORRECTIVE** plan, recommending using Makana **5 TIMES** a week until you **ELIMINATE** these discomforts.';
        } else if (plan === 'c2') {
          badge =
            ', so we have placed you on the **MEDIUM CORRECTIVE** plan, recommending using Makana **5 TIMES** a week until you **ELIMINATE** these discomforts.';
        } else if (plan === 'c1') {
          badge =
            ', so we have placed you on the **LOW CORRECTIVE** plan, recommending using Makana **3 TIMES** a week to **prevent** these discomforts from becoming significant.';
        }
        return badge;
      },
      setPriorities: (priority1: string, priority2: string, z: number) => {
        if (z === 0) {
          // No real priorities, both assigned
          return `As you have not reported significant discomfort, we have assigned **${priority1}** and **${priority2}** as your priorities, considering the most common discomforts at work.`;
        } else if (z === 1) {
          // Only one real priority, another assigned
          return `You have declared **${priority1}** as your main priority. We have also added **${priority2}** as your second priority, considering the most common discomforts at work.`;
        } else {
          // Both priorities real
          return `According to the discomforts you reported, your priorities to address are **${priority1}** and **${priority2}**.`;
        }
      },
      setRandomEnd: () => {
        const messages = [
          ' Remember that **your well-being is essential** for your performance.',
          ' **We are here to help you!**',
          ' Remember that **your body is your main work tool**!',
        ];
        const randomIndex = Math.floor(Math.random() * messages.length);
        return messages[randomIndex];
      },
    },
  },
  cohen: {
    introTitle: '',
    introContent: '',
    option1: 'Never',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
    question8: '',
    question9: '',
    question10: '',
  },
  charlie: {
    title: 'How can we help you?',
    molestias: 'Discomfort',
    cansancio: 'Fatigue',
    relajacion: 'Relaxation',
    tension: 'Stress and tension',
    hinchazon: 'Swelling',
    l: 'Which area is affected?',
    l1: 'Neck',
    l2: 'Shoulders',
    l3: 'Arm/Elbow',
    l4: 'Wrist/Hand',
    l5: 'Upper Back',
    l6: 'Lower Back',
    l7: 'Hips/Legs',
    l8: 'Knees',
    l9: 'Ankles/Feet',
    a: 'How long have you had discomfort?',
    a1: 'Less than a week',
    a2: 'Between one and four weeks',
    a3: 'Between one and two months',
    a4: 'More than two months',
    i: 'What is the intensity of the discomfort?',
    i1: 'Low intensity',
    i2: 'Medium intensity',
    i3: 'High intensity',
    h: 'Is there swelling in the area?',
    h0: 'No',
    h1: 'Yes',
    s: 'How tired are you?',
    s0: 'I am simply tired',
    s1: 'I am sleepy',
    t: 'Is it due to a bump, fall, or twist?',
    t0: 'No',
    t1: 'Yes',
  },
};

// x=dolor más alto, y=aparicion del dolor, z=cantidad de zonas con dolor.
